export default class SmoothScroll {
  constructor() {
    this.links = [...document.querySelectorAll('a[href*="#"]')];
    if (!this.links.length) return;
    this.bindEvent();
  }

  bindEvent() {
    this.links.forEach((link) => {
      link.addEventListener("click", (event) => this.smoothScroll(event));
    });
  }

  smoothScroll(event) {
    const clickedUrl = new URL(event.currentTarget.href);
    const currentUrl = new URL(window.location.href);

    if (clickedUrl.pathname === currentUrl.pathname) {
      event.preventDefault();
      event.stopImmediatePropagation();

      const target = clickedUrl.hash || 0;

      gsap.to(window, {
        duration: 1,
        scrollTo: target,
        ease: "power2.out",
      });
    }
  }
}
