export default class Sort {
  constructor() {
    this.sort = document.querySelector("[data-sort]");
    if (!this.sort) return;
    this.eventDateBtn = this.sort.querySelector('[data-sort-el="event-date"]');
    this.japaneseOrderBtn = this.sort.querySelector('[data-sort-el="japanese-order"]');
    this.list = this.sort.querySelector('[data-sort-el="list"]');
    this.items = [...this.list.querySelectorAll('[data-sort-el="item"]')];
    this.btns = [this.eventDateBtn, this.japaneseOrderBtn];
    this.bindEvents();
  }

  get classes() {
    return {
      isCurrent: "is-current",
    };
  }

  bindEvents() {
    this.eventDateBtn.addEventListener("click", () => {
      this.addClass(this.eventDateBtn);
      this.sortByEventDate();
    });
    this.japaneseOrderBtn.addEventListener("click", () => {
      this.addClass(this.japaneseOrderBtn);
      this.sortByJapaneseOrder();
    });
  }

  addClass(btn) {
    this.btns.forEach((btn) => btn.classList.remove(this.classes.isCurrent));
    setTimeout(() => btn.classList.add(this.classes.isCurrent));
  }

  sortByEventDate() {
    this.items.sort((a, b) => {
      const dateA = new Date(a.querySelector("[data-event-date]").getAttribute("data-event-date"));
      const dateB = new Date(b.querySelector("[data-event-date]").getAttribute("data-event-date"));
      return dateA - dateB;
    });
    this.updateDOM();
  }

  sortByJapaneseOrder() {
    this.items.sort((a, b) => {
      const rubyA = a.querySelector("[data-ruby]").getAttribute("data-ruby");
      const rubyB = b.querySelector("[data-ruby]").getAttribute("data-ruby");
      return rubyA.localeCompare(rubyB, "ja");
    });
    this.updateDOM();
  }

  updateDOM() {
    this.list.innerHTML = "";
    this.items.forEach((item) => this.list.appendChild(item));
  }
}
