export default class Entry {
  constructor() {
    this.checkbox = document.querySelector("[data-entry-checkbox]");
    this.entry = document.querySelector("[data-entry]");
    if (!this.checkbox || !this.entry) return;

    this.bindEvents()
  }

  get classes() {
    return {
      isDisabled: "is-disabled",
    };
  }

  bindEvents() {
    this.checkbox.addEventListener('change', this.toggleDisabled.bind(this));
  }

  toggleDisabled() {
    if (this.checkbox.checked) {
      this.entry.classList.remove(this.classes.isDisabled);
    } else {
      this.entry.classList.add(this.classes.isDisabled);
    }
  }
}