const config = {
  viewport: {
    sm: '767',
    md: '768',
  },
  breakPoint: {
    sp: '(max-width: 767px)',
    pc: '(min-width: 768px)',
  },
  path: {
    json: '/univ/mejinavi2024/assets/json/',
  },
}

export default config
