import { config } from '../core';

export default class CardSlider {
  constructor() {
    this.slider = document.querySelector("[data-card-slider]");
    if (!this.slider) return;
    this.slides = [...this.slider.querySelectorAll('[data-card-slide]')];
    this.paginations = this.slider.querySelector("[data-card-slider-paginations]");
    this.dots = [...this.paginations.querySelectorAll('span')];
    this.mobile = window.innerWidth < config.viewport.sm
    this.animate();
  }

  get classes() {
    return {
      isActive: "is-active",
    };
  }

  animate() {
    const animation = gsap.timeline({ repeat: -1, repeatDelay: 1 })
      .add(this.slideAnimation(2, 1, 0, 1))
      .add(this.slideAnimation(1, 0, 2, 2))
      .add(this.slideAnimation(0, 2, 1, 0))
  }

  slideAnimation(index1, index2, index3, dotIndex) {
    return gsap.timeline({
      defaults: {
        ease: "power1.out",
        onStart: () => {
          setTimeout(() => {
            this.slides[index1].style.zIndex = 1
            this.slides[index2].style.zIndex = 3
            this.slides[index3].style.zIndex = 2
          }, 266);
        },
        onComplete: () => this.setActiveDot(dotIndex)
      }})
      .to(this.slides[index1], {
        opacity: 0,
        x: this.mobile ? '-27.3vw' : -155,
        y: this.mobile ? 0 : -65,
        rotate: -8.5,
        duration: 0.26,
        onComplete: () => {
          setTimeout(() => {
            gsap.to(this.slides[index1], { opacity: 1, x: this.mobile ? '-17.3vw' : -135, y: this.mobile ? 0 : -59, rotate: -6.38, duration: 0.16 })
          }, 467);
        }
      }, '+=3.0')
      .to(this.slides[index2], { x: 0, y: 0, rotate: 3.84, duration: 0.9 }, '<')
      .to(this.slides[index3], { x: this.mobile ? '26.6vw' : 102, y: this.mobile ? '10.6vw' : 102, rotate: -0.81, duration: 0.9 }, '<')
  }

  setActiveDot(index) {
    this.dots.forEach((dot, i) => {
      if (i === index) {
        dot.classList.add(this.classes.isActive);
      } else {
        dot.classList.remove(this.classes.isActive);
      }
    });
  }
}