export default class Accordion {
  constructor() {
    this.elements = [...document.querySelectorAll('[data-accordion]')];
    if (!this.elements.length) return;

    this.bindEvents();
  }

  get classes() {
    return {
      isOpened: 'is-opened',
    }
  }

  bindEvents() {
    this.elements.forEach(element => {
      const trigger = element.querySelector('[data-accordion-el="trigger"]');
      const content = element.querySelector('[data-accordion-el="content"]');
      trigger.addEventListener("click", () => {
        element.classList.contains(this.classes.isOpened) ? this.hide(element) : this.show(element);
      });
    });
  }

  show(element) {
    element.classList.add(this.classes.isOpened)
  }

  hide(element) {
    element.classList.remove(this.classes.isOpened)
  }
}
