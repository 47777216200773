export default class LocalNav {
  constructor() {
    this.nav = document.querySelector("[data-local-nav]");
    this.watches = [...document.querySelectorAll("[data-watch]")];
    if (!this.nav || !this.watches) return;
    this.init();
  }

  get classes() {
    return {
      isCurrent: "is-current",
    };
  }

  init() {
    this.intersectionObserver = new IntersectionObserver(this.onIntersection.bind(this), { rootMargin: "0px 0px -99% 0px" });
    this.watches.forEach((target) => this.intersectionObserver.observe(target));
  }

  onIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) this.updateNav(entry.target);
    });
  }

  updateNav(target) {
    const currentActive = this.nav.querySelector(`.${this.classes.isCurrent}`);
    if (currentActive !== null) currentActive.classList.remove(this.classes.isCurrent);

    if (target.id) {
      const newActive = document.querySelector(`a[href='#${target.id}']`);
      if (newActive) {
        const listItem = newActive.parentElement;
        listItem.classList.add(this.classes.isCurrent);
      }
    }
  }
}
