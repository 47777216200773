export default class Voice {
  constructor() {
    this.voice = document.querySelector("[data-voice]");
    if (!this.voice) return;
    this.list = document.querySelector('[data-voice="list"]');
    this.moreBtn = document.querySelector('[data-voice="btn-more"]');
    this.bindEvents();
  }

  get classes() {
    return {
      isShow: 'is-all-show',
    }
  }

  bindEvents() {
    this.moreBtn.addEventListener("click", () => this.addClass());
  }

  addClass() {
    this.list.classList.add(this.classes.isShow)
  }
}
