import Loader from 'components/Loader'
import SmoothScroll from 'components/SmoothScroll'
import CardSlider from 'components/CardSlider'
import Sort from 'components/Sort'
import LocalNav from 'components/LocalNav'
import Voice from 'components/Voice'
import Accordion from 'components/Accordion'
import FixedButton from 'components/FixedButton'
import Entry from 'components/Entry'
import Simplebar from 'components/Simplebar'

window.addEventListener('DOMContentLoaded', () => {
  const loader = new Loader()
  const smoothScroll = new SmoothScroll()
  const cardSlider = new CardSlider()
  const sort = new Sort()
  const localNav = new LocalNav()
  const voice = new Voice()
  const accordion = new Accordion()
  const fixedButton = new FixedButton()
  const entry = new Entry()
  const simplebar = new Simplebar()
})
