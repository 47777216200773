export default class FixedButton {
  constructor() {
    this.fixedRelease = document.querySelector("[data-fixed-release]");
    this.btn = document.querySelector("[data-fixed-button]");
    if (!this.fixedRelease || !this.btn) return;
    this.init();
  }

  get classes() {
    return {
      isFixed: "is-fixed",
      isHidden: "is-hidden",
    };
  }

  init() {
    this.btn.classList.add(this.classes.isFixed);
    this.scrollTrigger();
  }

  scrollTrigger() {
    ScrollTrigger.create({
      trigger: this.fixedRelease,
      start: "top bottom",
      end: "bottom -=500vh",
      onToggle: self => this.toggleFixedButton(self),
    });
  }

  toggleFixedButton(self) {
    if (self.isActive) {
      this.btn.classList.remove(this.classes.isFixed);
      this.btn.classList.add(this.classes.isHidden);
      gsap.to(this.btn, { autoAlpha: 0, duration: 0.3, ease: 'none' })
    } else {
      this.btn.classList.add(this.classes.isFixed);
      this.btn.classList.remove(this.classes.isHidden);
      gsap.to(this.btn, { autoAlpha: 1, duration: 0.3, ease: 'none' })
    }
  }
}
